/* Header */
.custom-navbar {
  background-color: #fff;
  margin-bottom: 0;
}

.custom-brand {
  overflow: hidden;
  padding: 10px 20px;
  /* background: #009970; */
  text-decoration: #009970;
}
/* nav{background: #009970;} */
.custom-brand span {
  font-size: 20px;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  color: #fff;
  text-decoration: none;
}

.custom-brand span:hover {
  color: #fff;
  text-decoration: none;
}

.custom-nav-link {
  color: #333;
  font-weight: bold;
  margin-right: 0.5rem;
  font-size: 14px;
  padding: 0.5rem;
}

.custom-nav-link:hover,
.custom-nav-link.active {
  color: #009970;
}

.custom-get-started {
  background-color: #009970;
  color: #fff;
  padding: 0.3rem 0.7rem;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
}

.custom-get-started:hover {
  background-color: #218838;
  color: #fff;
}

.custom-navbar-container {
  margin-top: 30px;
}

/* Mobile Navigation */
@media (max-width: 992px) {
  .custom-navbar {
    background-color: #009970;
  }

  .nav {
    background-color: #009970;
  }

  .header {
    background-color: #009970;
  }

  .custom-brand span {
    font-size: 24px;
  }
}

@media (max-width: 400px) {
  .custom-brand span {
    font-size: 20px;
  }
}

#header .active {
  color: #009970 !important;
}

#header {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  top: 0;
  background: #009970;
}
.getstarted,
.getstarted:focus {
  background: #009970;
  padding: 10px 25px;
  margin-left: 30px;
  margin-right: 15px;
  border-radius: 50px;
  color: #fff;
}

.getstarted:hover,
.getstarted:focus:hover {
  color: #fff;
  background: #00b383;
}

  
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  color: #444444;
  font-size: 14px;
  background: #f1f0f2;
}

#footer .footer-top {
  padding: 60px 0 30px 0;
  background: #f9f8f9;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h4 {
  font-size: 22px;
  margin: 0 0 30px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}

#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
  color: #777777;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #444444;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #00c28e;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #777777;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: #009970;
}

#footer .footer-newsletter {
  font-size: 15px;
}

#footer .footer-newsletter h4 {
  font-size: 16px;
  font-weight: bold;
  color: #444444;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 50px;
  text-align: left;
  border: 1px solid #e4e3e6;
}

#footer .footer-newsletter form input[type=email] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type=submit] {
  position: absolute;
  top: 0;
  right: -2px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #009970;
  color: #fff;
  transition: 0.3s;
  border-radius: 50px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-newsletter form input[type=submit]:hover {
  background: #00664b;
}

#footer .credits {
  padding-top: 5px;
  font-size: 13px;
  color: #444444;
}

#footer .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #009970;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .social-links a:hover {
  background: #00805d;
  color: #fff;
  text-decoration: none;
}



.pdf-download-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color:  #009970;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-right: 10px;
  /* padding-right: 30px; */
}

.pdf-download-button:hover {
  background-color: #028360;
}


.download-btn {
  background-color:  #009970;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
}

.download-btn:hover {
  background-color:  #028360;
}


/* .QR-img{
  
  width: 300px;
  height: 400px;

} */
.registration h1 {
  text-align: center;
  margin-bottom: 30px;
}

.registration h2 {
  text-align: center;
  margin-bottom: 30px;
}

.registration form {
  display: none; 
}

.registration .form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
}

.form-group input[type="text"],
.form-group input[type="number"],
.form-group input[type="file"],
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23333" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 18px;
  padding-right: 30px;
}

.form-group input[type="file"] {
  padding: 7px;
  position: relative;
  overflow: hidden;
}

.form-group input[type="file"]::-webkit-file-upload-button {
  background-color: #009970;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form-group input[type="file"]:focus::-webkit-file-upload-button {
  outline: none;
}

.form-group button {
  width: 300px;
  padding: 10px 20px;
  background-color: #009970;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form-group button:hover {
  background-color: #00765e;
}

.toggle-buttons {
  text-align: center;
}

.toggle-buttons button {
  margin: 0 10px;
  padding: 10px 20px;
  background-color: #ccc;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
}

.toggle-buttons button.active {
  background-color: #009970;
  color: #fff;
}

.star{
  color: red;
  
}
#member-form-btn{
  /* padding-bottom: 5px; */
  margin-bottom: 5px;
}
#vehicle-form-btn{
  margin-top: 5px;
}

.submit-btn{
  margin-top: 10px;
  align-items: center;
}

.form-heading{
  text-align: center;
  font-weight: 900;
  color: #009970;
  padding-bottom: 5%;
}




