
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Global Styles */
body {
  font-family: Arial, sans-serif;
}

.container-fluid {
  padding-top: 60px;
}


.container {
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
}

.logo {
  margin-right: auto;
}

.profile-pic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

.dropdown-menu {
  background-color: #343a40;
  background: #343a40;
}

.dropdown-menu a {
  color: white !important;
}

.dropdown-menu a:hover {
  background-color: #5a6268 !important;
}
.dropdown-menu.show {
  background-color: #343a40;
}
/* .dropdown-item{
  background-color:#009970;
  border-radius: 20%;
  padding: 20%;
} */

/* Sidebar */
.sidebar {
  background-color: #2b3e50;
  color: white;
  padding: 20px;
  width: 20%;
  height: 100vh;
  /* padding-bottom: 25%;  */
  padding-top: 5%;
  margin-top: -5%;
}

.profile {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10%;
}

.profile img {
  width: 100px;
  border-radius: 30%;
}

nav ul {
  list-style: none;
  padding: 0;
}

/* nav ul li {
  margin-bottom: 10px;
} */

/* Main Content */
.main-content {
  padding: 20px;
  margin-top: 5%;
}

.stats {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.stat-item {
  flex: 1 1 calc(50% - 20px);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat-item h2 {
  margin-bottom: 10px;
  font-size: 2rem;
  color: #009970;
}

.stat-item a {
  color: #009970;
  font-weight: 600;
  text-decoration: none;
  margin-top: 10px;
  display: inline-flex;
  align-items: center;
}

.stat-item a i {
  margin-right: 5px;
}


.table-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  overflow-x: auto;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

button {
  background: #009970;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.view {
  color: #009970;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.view i {
  margin-right: 5px;
}

/* Media queries */
@media (min-width: 1000px) and (max-width: 1400px) {
  .sidebar {
    width: 25%;
    
  }
}

@media (max-width: 1000px) {
  .sidebar {
    width: 100%;
    height: 50%;
    /* padding-bottom: 0%; */
    padding-top: 0%;
    /* margin-top: -5%; */
    /* display: flex; */
  }
  /* .row{display: inline-flex;} */
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    padding-bottom: 0%;
  }
}

/* Media query for max-width: 550px */
@media (max-width: 550px) {
  #header .logo h1 {
    font-size: 20px; /* Reduce logo size to h3 */
  }

  .profile-pic {
    width: 20px; /* Reduce profile pic size */
    height: 20px;
  }

  .dropdown-item span {
    display: none; /* Hide name for dropdown */
  }
  .dropdown-item{
    background-color:transparent;
  }
  .sidebar{
    padding-bottom: 0%;
    margin-top:-2%;
  }
.sidebar.col-lg-3 {margin-top: -2%;}
  
}

#member{
  color: #009970;
  font-weight: 700;
  /* padding-top: 5%; */
}
#vehicle{
  color: #009970;
  font-weight: 700;

}

/*--------------------------------------------------------------
MemberView
--------------------------------------------------------------*/
.memberview{
  margin: 5%;
  /* padding-top: 5%; */
  /* margin-top: 50%; */
}
@media (max-width: 550px) {
  .memberview{
    margin: 5%;
    padding-top: 10%;
  }
}
@media (max-width: 400px) {
  .memberview{
    margin: 5%;
    padding-top: 15%;
  }
}
.edit-download-btn button{
  width: 300px;
  padding: 10px 20px;
  background-color: #009970;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5%;
}
.edit-download-btn button:hover {
  background-color: #00765e;
}
.dashboard-nav a{
color: #ffffff;
}
/* .dashboard-nav a:hover{
color: #00765e;
} */
.dashboard-nav .active{
  /* color: #009970; */
  /* font-weight:bolder; */
  font-size: x-large;
}
nav ul {
  list-style: none;
  padding: 0;
}

nav ul li {
  margin-bottom: 10px;
}

nav ul li a {
  color: white;
  text-decoration: none;
  padding: 10px 15px;
  display: block;
  border-radius: 4px;
  transition: background 0.3s, color 0.3s;
}

nav ul li a:hover,
nav ul li a.active {
  background-color: #00765e;
  color: #fff;
}
.logo-section img {
  padding-right: 5%;
  padding: 0;
  /* margin: 0; */
  max-height: 80px;
  
  align-items: center;
}
.logo-section{
  padding-top: 55px;
  justify-content: center;

  /* display: flex; */
  /* align-items: center; */
}

.logo-img{
  padding: 10%;
  /* align-content: center; */
}
.bold{
  font-weight: 900;
  text-transform: uppercase;
  color: #009970;
}

/* .sidebar .col-lg-3 {} */


.sealImg{
  max-width: 200px;
}
.right-aligned {
  display: flex;
  justify-content: flex-end;
}

.bordered-content-inside{

  border: 2px solid #000;
}