@import '~@fortawesome/fontawesome-free/css/all.min.css';


.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    background-color: #f5f5f5;
}

.login-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-image img {
    max-width: 40%;
    height: auto;
    /* padding-right:5%; */
}

.login-form {
    flex: 1;
    max-width: 400px;
    padding: 2rem;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    /* margin-right: 55%; */
    justify-content: center;
    align-items: center;
}

.login-form h2 {
    margin-bottom: 1.5rem;
    text-align: center;
}

.form-group {
    position: relative;
    margin-bottom: 1.5rem;
}

.form-group input {
    width: 100%;
    padding: 0.75rem;
    padding-left: 2.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-group .icon {
    position: absolute;
    top: 50%;
    left: 0.75rem;
    transform: translateY(-50%);
}

.user-icon:before {
    content: '\f007';
    /* FontAwesome user icon unicode */
    font-family: 'FontAwesome';
}

.eye-icon:before {
    content: '\f06e';
    /* FontAwesome eye icon unicode */
    font-family: 'FontAwesome';
}

.form-links {
    text-align: right;
    margin-bottom: 1rem;
}

.form-links a {
    color: #007bff;
    text-decoration: none;
}

.form-links a:hover {
    text-decoration: underline;
}

.login-button {
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    background: linear-gradient(to right, #4facfe, #00f2fe);
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
}

.login-button:hover {
    background: linear-gradient(to right, #00f2fe, #4facfe);
}

.signup-link {
    text-align: center;
    margin-top: 1rem;
}

.signup-link a {
    color: #007bff;
    text-decoration: none;
}

.signup-link a:hover {
    text-decoration: underline;
}